import React from 'react';

import styled from 'styled-components';

import { useTranslation } from 'lib/i18n';

import AboutBlock from '../AboutBlock';

import AliasesSvg from './Aliases.svg';
import PreviewSvg from './Preview.svg';
import CalendarSvg from './Calendar.svg';
import ColorSvg from './Color.svg';
import CollapseSvg from './Collapse.svg';
import AssignSvg from './Assign.svg';

const StyledSecondaryFeatureBlock = styled(AboutBlock)`
  padding-top: 30px;
  padding-bottom: 2px;
`;

const Icon = styled.div`
  margin-bottom: 11px;
  svg {
    display: block;
  }
`;

const Title = styled.h3`
  margin: 0 0 16px 0;
  ${p => p.theme.fonts.titleBold}
  font-size: 3.2rem;
  line-height: 3.1rem;
`;

const Body = styled.div`
  font-size: 2.2rem;
  line-height: 2.6rem;
`;

const StyledFeature = styled.div`
  width: 310px;
  margin: 0 20px 66px 0;
  flex-grow: 1;
`;

const Features = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 26px;
`;

interface Props {
  icon: React.ReactNode;
  title: string;
  text: string;
}

const Feature = ({ icon, title, text }: Props) => {
  const { t } = useTranslation('about');
  return (
    <StyledFeature>
      <Icon>{icon}</Icon>
      <Title>{t(title)}</Title>
      <Body>{t(text)}</Body>
    </StyledFeature>
  );
};

const SecondaryFeatureBlock = () => {
  return (
    <StyledSecondaryFeatureBlock>
      <Features>
        <Feature
          icon={<AliasesSvg />}
          title="secondaryFeaturesBlock.aliases.title"
          text="secondaryFeaturesBlock.aliases.text"
        />
        <Feature
          icon={<PreviewSvg />}
          title="secondaryFeaturesBlock.preview.title"
          text="secondaryFeaturesBlock.preview.text"
        />
        <Feature
          icon={<CalendarSvg />}
          title="secondaryFeaturesBlock.calendar.title"
          text="secondaryFeaturesBlock.calendar.text"
        />
        <Feature
          icon={<ColorSvg />}
          title="secondaryFeaturesBlock.color.title"
          text="secondaryFeaturesBlock.color.text"
        />
        <Feature
          icon={<CollapseSvg />}
          title="secondaryFeaturesBlock.collapse.title"
          text="secondaryFeaturesBlock.collapse.text"
        />
        <Feature
          icon={<AssignSvg />}
          title="secondaryFeaturesBlock.assign.title"
          text="secondaryFeaturesBlock.assign.text"
        />
      </Features>
    </StyledSecondaryFeatureBlock>
  );
};

export default SecondaryFeatureBlock;
