import styled from 'styled-components';
import Image from 'next/image';

import { mediaQuery } from 'helpers/style-utils';

import AboutBlock, { Inner } from '../AboutBlock';

export const StyledQuotesBlock = styled(AboutBlock)`
  overflow: hidden;
  padding: 103px 0 0 0;
  background-color: ${p => p.theme.colors.black};
  ${mediaQuery(600)`
    padding-top: 60px;
  `}

  ${Inner} {
    max-width: 1120px;
  }
`;

export const Title = styled.h1`
  ${p => p.theme.fonts.titleBold}
  font-size: 8.4rem;
  line-height: 84%;
  letter-spacing: -0.04em;
  color: ${p => p.theme.colors.white};
  margin: 0 -10px 66px;

  ${mediaQuery(900)`
    font-size: 5rem;
    margin: 0 0 40px;
  `}
`;

export const SubTitle = styled.h2`
  ${p => p.theme.fonts.titleBold}
  font-size: 4.4rem;
  line-height: 131%;
  letter-spacing: -0.06em;
  color: ${p => p.theme.colors.white};
  text-align: center;
  margin-bottom: 107px;

  ${mediaQuery(900)`
    font-size: 3rem;
    line-height: 110%;
    margin-bottom: 80px;
  `}
`;

export const Quotes = styled.div`
  display: flex;
  flex-wrap: nowrap;
  position: relative;
  ${mediaQuery(900)`
    display: block;
  `}
`;

export const QuoteContainer = styled.figure`
  flex-grow: 0;
  flex-basis: 500px;
  width: 500px;
  margin: 0 37px 36px 28px;
  padding: 0;

  ${mediaQuery(900)`
    flex: none;
    width: auto;
  `}
  ${mediaQuery(600)`
    margin-bottom: 60px;
  `}
`;

export const QuoteText = styled.blockquote`
  ${p => p.theme.fonts.titleBold}
  font-size: 2.6rem;
  line-height: 120%;
  letter-spacing: -0.04em;
  color: ${p => p.theme.colors.white};
  margin: 0;
  margin-bottom: 29px;
  strong {
    font-weight: bold;
  }
  ${mediaQuery(600)`
    font-size: 2rem;
    margin-bottom: 12px;
  `}
`;

export const QuoteAuthor = styled.figcaption`
  color: ${p => p.theme.colors.bombay};
`;

export const AuthorName = styled.p`
  font-size: 2rem;
  line-height: 120%;
  ${mediaQuery(600)`
    font-size: 1.8rem;
  `}
`;

export const AuthorPost = styled.p`
  font-size: 1.8rem;
  line-height: 120%;
  ${mediaQuery(600)`
    font-size: 1.6rem;
  `}
`;

export const QuoteImage = styled(Image).attrs({ width: 40, height: 40 })`
  border-radius: 50%;
  float: left;
  margin: 3px 9px 0 0;
`;
