import React from 'react';

import styled from 'styled-components';

import AboutHeader from './header/AboutHeader';
import AboutFooter from './footer/AboutFooter';

import HeadingBlock from './heading-block/HeadingBlock';
import QuotesBlock from './quotes-block/QuotesBlock';
import DesignFeatureBlock from './features-block/DesignFeatureBlock';
import CheckboxesFeatureBlock from './features-block/CheckboxesFeatureBlock';
import HeadingsFeatureBlock from './features-block/HeadingsFeatureBlock';
import ChatFeatureBlock from './features-block/ChatFeatureBlock';
import SecondaryFeaturesBlock from './secondary-features-block/SecondaryFeaturesBlock';
import KanbanBlock from './kanban-block/KanbanBlock';
import RequestAccessBlock from './request-access-block/RequestAccessBlock';

const StyledAboutPage = styled.div`
  background-color: ${p => p.theme.currentTheme.body};
`;

const StyledHeader = styled.div`
  background-color: ${p => p.theme.currentTheme.panel};
`;

const AboutPage = () => (
  <StyledAboutPage>
    <StyledHeader>
      <AboutHeader />
      <HeadingBlock />
    </StyledHeader>
    <QuotesBlock />
    <DesignFeatureBlock />
    <CheckboxesFeatureBlock />
    <HeadingsFeatureBlock />
    <ChatFeatureBlock />
    <SecondaryFeaturesBlock />
    <KanbanBlock />
    <RequestAccessBlock />
    <AboutFooter />
  </StyledAboutPage>
);

export default AboutPage;
