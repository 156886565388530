import React from 'react';

import styled from 'styled-components';
import { mediaQuery } from 'helpers/style-utils';

import { useTranslation, Trans } from 'lib/i18n';

import AboutBlock from '../AboutBlock';

interface FeatureBlock {
  icon: React.ReactNode;
  title: string;
  text: string;
  note?: string;
  screenshot: React.ReactNode;
}

const Icon = styled.div`
  margin-bottom: 13px;
  svg {
    display: block;
  }
`;

const Title = styled.h3`
  ${p => p.theme.fonts.titleBold}
  font-size: 5rem;
  line-height: 6.5rem;
  margin: 0 0 6px 0;
`;

const Body = styled.div`
  margin-bottom: 41px;
  p {
    font-size: 2.8rem;
    line-height: 120%;
    margin-bottom: 3.4rem;
    ${mediaQuery(900)`
      font-size: 2.4rem;
      margin-bottom: 2.8rem;
    `}
  }
  ${mediaQuery(800)`
    margin-bottom: 20px;
    p {
      margin-bottom: 1.8rem;
    }
  `}
`;

const Note = styled.div`
  font-size: 1.6rem;
  line-height: 2.1rem;
  color: ${p => p.theme.colors.gray};
  ${mediaQuery(800)`
    margin-bottom: 2.1rem;
  `}
`;

const Screenshot = styled.div`
  border-radius: 6px;
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
    0px 1px 2px ${p => p.theme.colors.transparentGray};
  overflow: hidden;
  img {
    display: block;
  }
`;

const Container = styled.div`
  display: flex;
  ${mediaQuery(800)`
    display: block;
  `}
`;

const Left = styled.div`
  width: 420px;
  margin-right: 51px;
  flex-shrink: 0;
  ${mediaQuery(900)`
    width: 340px;
  `}
  ${mediaQuery(800)`
    width: 100%;
  `}
`;
const Right = styled.div``;

const StyledAboutBlock = styled(AboutBlock)`
  overflow: hidden;
`;

const FeatureBlock = ({ icon, title, text, note, screenshot }: FeatureBlock) => {
  const { t } = useTranslation('about');
  return (
    <StyledAboutBlock isDotted={true}>
      <Container>
        <Left>
          <Icon>{icon}</Icon>
          <Title>
            <Trans i18nKey={title} t={t} />
          </Title>
          <Body>
            <Trans i18nKey={text} t={t} />
          </Body>
          {note && (
            <Note>
              <Trans i18nKey={note} t={t} />
            </Note>
          )}
        </Left>
        <Right>
          <Screenshot>{screenshot}</Screenshot>
        </Right>
      </Container>
    </StyledAboutBlock>
  );
};

export default FeatureBlock;
