/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import styled from 'styled-components';

import { useTranslation, Trans } from 'lib/i18n';

import Link from 'next/link';

import { mediaQuery } from 'helpers/style-utils';

import AboutBlock from '../AboutBlock';
import { staticUrl, contextUrl } from '../lib/utils';

const TopAboutBlock = styled(AboutBlock)`
  padding-bottom: 0;
`;

const BottomAboutBlock = styled(AboutBlock)`
  background: ${p => p.theme.currentTheme.body};
  padding-top: 0;
  padding-bottom: 0;
`;

const Slogan = styled.h1`
  position: relative;
  z-index: 10;
  width: 770px;
  margin-right: 10px;
  ${p => p.theme.fonts.titleBold}
  font-size: 12rem;
  line-height: 84%;
  letter-spacing: -0.04em;
  em {
    font-style: normal;
    color: ${p => p.theme.currentTheme.primary};
  }
  span {
    &::after {
      content: '';
      background: transparent;
      width: 320px;
      height: 100px;
      position: absolute;
      margin-left: -300px;
      z-index: -10;
    }
  }
  ${mediaQuery(960)`
    font-size: 11rem;
  `}
  ${mediaQuery(900)`
    font-size: 10rem;
  `}
  ${mediaQuery(850)`
    font-size: 9rem;
  `}
  ${mediaQuery(800)`
    span {
      &::after {
        display: none;
      }
    }
    width: 100%;
  `}
  ${mediaQuery(650)`
    font-size: 6rem;
  `}
  ${mediaQuery(500)`
    font-size: 5rem;
  `}
`;

const Right = styled.div`
  width: 307px;
  margin-top: 24px;

  ${mediaQuery(800)`
    width: auto;
    margin-top: 20px;

    p {
      width: 80%;
    }
  `}
`;

const Description = styled.p`
  font-size: 2rem;
  line-height: 140%;
  margin-bottom: 42px;
  color: ${p => p.theme.currentTheme.notes};
  ${mediaQuery(900)`
    font-size: 1.8rem;
  `}
  ${mediaQuery(850)`
    font-size: 1.7rem;
  `}
  ${mediaQuery(800)`
    font-size: 1.7rem;
  `}
`;

const SloganContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding-bottom: 226px;

  ${mediaQuery(800)`
    display: block;
    padding-bottom: 100px;
  `}
`;

const RequestAccess = styled.button`
  width: 100%;
  max-width: 238px;
  padding: 12px 0;
  font-size: 2rem;
  line-height: 2.4rem;
  font-weight: bold;
  margin-bottom: 22px;
  width: 240px;
  margin: 0 auto;
  display: block;
  color: ${p => p.theme.colors.white};
  background: ${p => p.theme.colors.dodgerBlue};
  border-radius: 6px;
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
    0px 1px 2px ${p => p.theme.colors.transparentGray};
  transition: box-shadow 0.1s ease;
  &:hover,
  &:focus {
    box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
      0px 1px 2px ${p => p.theme.colors.transparentGray},
      0 0 0 2px ${p => p.theme.colors.dodgerBlue};
  }
`;

const Screenshot = styled.div`
  box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
    0px 1px 2px ${p => p.theme.colors.transparentGray};
  border-radius: 8px;
  max-width: 960px;
  overflow: hidden;
  transform: translateY(-120px);
  margin: 0 auto;
  img,
  video {
    display: block;
    width: 100%;
    height: 52.5%;
  }

  ${mediaQuery(800)`
    transform: translateY(-36px);
  `}
`;

const OrganizeContainer = styled.div`
  text-align: center;
`;

const OrganizeTitle = styled.h1`
  font-size: 10rem;
  line-height: 120%;
  letter-spacing: -0.06em;

  ${mediaQuery(960)`
    font-size: 4rem;
    line-height: 100%;
    margin-bottom: 10px;
  `}
`;

const OrganizeDescription = styled.div`
  font-size: 3.8rem;
  line-height: 120%;
  letter-spacing: -0.06em;
  margin-bottom: 200px;

  strong {
    ${p => p.theme.fonts.titleBold}
  }

  ${mediaQuery(800)`
    font-size: 1.7rem;
    margin-bottom: 80px;
  `}
`;

const HeadingBlock = () => {
  const { t } = useTranslation('about');
  return (
    <>
      <TopAboutBlock>
        <SloganContainer>
          <Slogan>
            <span>
              <Trans i18nKey="headingBlock.slogan" t={t} />
            </span>
          </Slogan>
          <Right>
            <Description>
              <Trans i18nKey="headingBlock.description" t={t} />
            </Description>
            <Link href="/signup">
              <a>
                <RequestAccess>{t('headingBlock.signIn')}</RequestAccess>
              </a>
            </Link>
          </Right>
        </SloganContainer>
      </TopAboutBlock>
      <BottomAboutBlock>
        <Screenshot>
          <video width="960" height="504" autoPlay={true} muted={true} loop={true}>
            <source
              src={staticUrl('about-screencast-21-09-2019.mp4', contextUrl.VIDEO)}
              type="video/mp4"
            />
          </video>
        </Screenshot>
        <OrganizeContainer>
          <OrganizeTitle>
            <Trans i18nKey="headingBlock.organize.title" t={t} />
          </OrganizeTitle>
          <OrganizeDescription>
            <Trans i18nKey="headingBlock.organize.description" t={t} />
          </OrganizeDescription>
        </OrganizeContainer>
      </BottomAboutBlock>
    </>
  );
};

export default HeadingBlock;
