import React from 'react';

import FeatureBlock from './FeatureBlock';
import Icon from './HeadingsFeatureBlockIcon.svg';
import { staticUrl, contextUrl } from '../lib/utils';

const DesignFeatureBlock = () => (
  <FeatureBlock
    icon={<Icon />}
    title="headingsFeatureBlock.title"
    text="headingsFeatureBlock.text"
    screenshot={
      <img
        src={staticUrl('features-headings.png', contextUrl.ABOUT)}
        width="960"
        height="504"
        alt=""
      />
    }
  />
);

export default DesignFeatureBlock;
