import React from 'react';
import { useTranslation } from 'lib/i18n';

import Link from 'next/link';
import { mediaQuery } from 'helpers/style-utils';

import styled from 'styled-components';

import TwitterIcon from 'components/shared/svg/TwitterIcon.svg';
// import InstagramIcon from 'components/shared/svg/InstagramIcon.svg';
import AboutBlock from '../AboutBlock';

const StyledAboutFooter = styled(AboutBlock)`
  background-color: ${p => p.theme.currentTheme.body};
  padding: 60px 0 0 0;
`;

const Container = styled.footer`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
`;

const Copyright = styled.p`
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin: 0 0 9px 0;
  white-space: nowrap;
`;

const Items = styled.ul`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 0 9px 0;
  padding: 0;
  ${mediaQuery(500)`
    width: 100%;
  `}
  ${mediaQuery(400)`
    -display: block;
  `}
`;

const Item = styled.li`
  list-style-type: none;
  font-size: 1.8rem;
  line-height: 2.1rem;
  margin: 0 9px 0 16px;
  white-space: nowrap;
  &:first-child {
    margin-left: 0;
  }
  ${mediaQuery(500)`
    margin-left: 0;
    margin-right: 0;
  `}
`;

const IconItem = styled(Item)`
  margin: 0 0 0 10px;
  transform: translateY(-3px);
`;

const AboutFooter = () => {
  const { t } = useTranslation('about');
  const year = new Date().getFullYear();

  return (
    <StyledAboutFooter>
      <Container>
        <Copyright>{t('footer.copyright', { year })}</Copyright>
        <Items>
          <Item>
            <Link href="/tos/privacy">
              <a>{t('footer.privacy')}</a>
            </Link>
          </Item>
          {/*
            <Item>
              <Link href="/tos/privacy-policy">
                <a>{t('footer.cookies')}</a>
              </Link>
            </Item>
          */}
          <Item>
            <Link href="/tos">
              <a>{t('footer.tos')}</a>
            </Link>
          </Item>
          <IconItem>
            <a href="https://twitter.com/columnsapp" target="_blank" rel="noopener noreferrer">
              <TwitterIcon />
            </a>
          </IconItem>
          {/*
            <IconItem>
              <a href="#TODO" target="_blank" rel="noopener noreferrer"><InstagramIcon /></a>
            </IconItem>
          */}
        </Items>
      </Container>
    </StyledAboutFooter>
  );
};

export default AboutFooter;
