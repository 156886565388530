import React from 'react';

import FeatureBlock from './FeatureBlock';
import Icon from './ChatFeatureBlockIcon.svg';
import { staticUrl, contextUrl } from '../lib/utils';

const DesignFeatureBlock = () => (
  <FeatureBlock
    icon={<Icon />}
    title="chatFeatureBlock.title"
    text="chatFeatureBlock.text"
    screenshot={
      <img src={staticUrl('features-chat.png', contextUrl.ABOUT)} width="960" height="504" alt="" />
    }
  />
);

export default DesignFeatureBlock;
