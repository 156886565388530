import React from 'react';
import { useTranslation } from 'lib/i18n';

import Link from 'next/link';

import styled from 'styled-components';
import { mediaSelector } from 'helpers/style-utils';

import LogoSvg from 'components/shared/svg/Logo.svg';

import { getCurrentUser } from 'components/users/WithCurrentUser/WithCurrentUser';
import Userpic from 'components/shared/Userpic/Userpic';

import Button from 'components/shared/Button/Button';
import AboutBlock from '../AboutBlock';

const StyledAboutBlock = styled(AboutBlock)`
  padding: 52px 0 108px 0;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
`;

const Logo = styled.div`
  font-size: 3.4rem;
  line-height: 4.1rem;
  letter-spacing: -0.05em;
  height: 41px;
  white-space: nowrap;
  margin-right: 30px;

  ${mediaSelector.mobile} {
    margin-right: 0px;
  }
`;

const Icon = styled(LogoSvg)`
  margin: 0 10px 0 0;
  transform: translateY(6px);
`;

const Nav = styled.ul`
  list-style-type: none;
  margin: 15px 0 0 0;
  padding: 0;

  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;

  ${mediaSelector.mobile} {
    margin-top: 12px;
  }
`;

const NavItem = styled.li`
  margin: 0;
  padding: 0;
  font-size: 2.2rem;
  line-height: 2.6rem;
  margin-left: 29px;
  white-space: nowrap;
  &:first-child {
    margin-left: 0;
  }

  ${mediaSelector.mobile} {
    margin-left: 0px;
  }
`;

const AboutNavItem = styled(NavItem)`
  ${mediaSelector.mobile} {
    display: none;
  }
`;

const SignInNavItem = styled(NavItem)`
  ${mediaSelector.mobile} {
    display: none;
  }
`;

const SignIn = styled(Button)`
  padding: 9px 22px 9px 20px;
  font-size: 1.8rem;
  line-height: 2rem;
  height: auto;
  transform: translateY(-6px);

  ${mediaSelector.mobile} {
    padding: 9px 12px 9px 13px;
    font-size: 1.6rem;
    line-height: 1.9rem;
  }
`;

const UserpicNavItem = styled(NavItem)`
  transform: translateY(-12px);
  margin-left: 19px;
`;

const NavLink = styled.a`
  cursor: pointer;

  ${mediaSelector.hoverSupported} {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const AboutHeader: React.FC<{}> = () => {
  const { t } = useTranslation('about');
  const currentUser = getCurrentUser();

  return (
    <StyledAboutBlock>
      <Container>
        <Logo>
          <Link href="/">
            <a>
              <Icon />
              {t('columns')}
            </a>
          </Link>
        </Logo>
        <Nav>
          <AboutNavItem>
            <Link href="/about">
              <NavLink>{t('header.about')}</NavLink>
            </Link>
          </AboutNavItem>
          {currentUser ? (
            <UserpicNavItem>
              <Link href="/">
                <a>
                  <Userpic
                    size={48}
                    title={currentUser.name}
                    image={currentUser.profileImage}
                    isHoverable={true}
                  />
                </a>
              </Link>
            </UserpicNavItem>
          ) : (
            <>
              <SignInNavItem>
                <Link href="/signin">
                  <NavLink>{t('header.signIn')}</NavLink>
                </Link>
              </SignInNavItem>
              <NavItem>
                <Link href="/signup">
                  <a>
                    <SignIn>{t('header.signUp')}</SignIn>
                  </a>
                </Link>
              </NavItem>
            </>
          )}
        </Nav>
      </Container>
    </StyledAboutBlock>
  );
};

export default AboutHeader;
