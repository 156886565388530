import React from 'react';

import styled from 'styled-components';

import { mediaSelector } from 'helpers/style-utils';

// prettier-ignore
const StyledAboutBlock = styled.div<{ isDotted: boolean }>`
  padding: 60px 0;

  ${p => p.isDotted && `
    background-image: linear-gradient(to right, ${p.theme.currentTheme.delimiter} 50%, ${p.theme.currentTheme.body} 0%);
    background-position: bottom;
    background-size: 8px 1px;
    background-repeat: repeat-x;
  `};
`;

export const Inner = styled.div`
  max-width: 1031px;
  margin: 0 auto;
  padding: 0 40px;
  ${mediaSelector.mobile} {
    padding: 0 20px;
  }
  transition: padding 0.1s;
`;

const AboutBlock: React.FC<{
  className?: string;
  isDotted?: boolean;
  children: React.ReactNode;
}> = ({ className, children, isDotted }) => (
  <StyledAboutBlock className={className} isDotted={!!isDotted}>
    <Inner>{children}</Inner>
  </StyledAboutBlock>
);

export default AboutBlock;
