import React from 'react';

import styled from 'styled-components';

import Link from 'next/link';

import { useTranslation } from 'lib/i18n';
import AboutBlock from '../AboutBlock';

const StyledRequestAccessBlock = styled(AboutBlock)`
  padding: 100px 0 60px 0;
`;

const Personal = styled.p`
  ${p => p.theme.fonts.titleBold}
  font-size: 4.8rem;
  line-height: 4.9rem;
`;

const Teams = styled.p`
  font-size: 4.8rem;
  line-height: 4.9rem;
`;

const RequestButton = styled.button`
  background: ${p => p.theme.colors.dodgerBlue};
  border-radius: 6px;

  font-weight: bold;
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${p => p.theme.colors.white};

  padding: 12px 45px 11px 45px;
  margin: 36px 0 60px 0;

  transition: box-shadow 0.1s ease;
  &:hover,
  &:focus {
    box-shadow: 0px 2px 6px ${p => p.theme.colors.transparentGray},
      0px 1px 2px ${p => p.theme.colors.dodgerBlue};
  }
`;

const Container = styled.div`
  text-align: center;
`;

const RequestAccessBlock = () => {
  const { t } = useTranslation('about');
  return (
    <StyledRequestAccessBlock>
      <Container>
        <Personal>{t('requestAccessBlock.personal')}</Personal>
        <Teams>{t('requestAccessBlock.teams')}</Teams>
        <Link href="/signup">
          <RequestButton>{t('requestAccessBlock.request')}</RequestButton>
        </Link>
      </Container>
    </StyledRequestAccessBlock>
  );
};

export default RequestAccessBlock;
