import React from 'react';

import FeatureBlock from './FeatureBlock';
import Icon from './CheckboxFeatureBlockIcon.svg';
import { staticUrl, contextUrl } from '../lib/utils';

const DesignFeatureBlock = () => (
  <FeatureBlock
    icon={<Icon />}
    title="checkboxesFeatureBlock.title"
    text="checkboxesFeatureBlock.text"
    note="checkboxesFeatureBlock.note"
    screenshot={
      <img
        src={staticUrl('features-checkbox.png', contextUrl.ABOUT)}
        width="960"
        height="504"
        alt=""
      />
    }
  />
);

export default DesignFeatureBlock;
