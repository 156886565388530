import React from 'react';

import styled from 'styled-components';
import { mediaQuery } from 'helpers/style-utils';

import { useTranslation, Trans } from 'lib/i18n';
import AboutBlock from '../AboutBlock';

const StyledKanbanBlock = styled(AboutBlock)`
  background: #32dabc;
  padding: 83px 0 37px 0;
`;

const Header = styled.h3`
  ${p => p.theme.fonts.titleBold}
  font-size: 6.8rem;
  line-height: 90%;
  letter-spacing: -0.04em;
  color: ${p => p.theme.colors.black};
  margin: 0 0 33px 0;
  ${mediaQuery(900)`
    width: 100%;
  `}
  ${mediaQuery(800)`
    font-size: 6rem;
  `}
`;

const Text = styled.div`
  width: 668px;
  margin-right: 26px;
  letter-spacing: -0.04em;
  color: ${p => p.theme.colors.black};
  p {
    font-size: 2.4rem;
    line-height: 130%;
    margin: 0 0 3.1rem 0;
  }
  strong {
    ${p => p.theme.fonts.titleBold}
  }
  ${mediaQuery(800)`
    width: 100%;
  `}
`;

const Kanban = styled.aside`
  width: 310px;
  border-left: 2px solid ${p => p.theme.colors.black};
  padding-left: 18px;
  font-size: 1.8rem;
  line-height: 2.5rem;
  color: ${p => p.theme.colors.black};

  em {
    ${p => p.theme.fonts.titleBold}
    font-style: normal;
  }

  ${mediaQuery(800)`
    width: 100%;
  `}
`;

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  ${mediaQuery(800)`
    display: block;
  `}
`;

const KanbanBlock = () => {
  const { t } = useTranslation('about');
  return (
    <StyledKanbanBlock>
      <Header>{t('kanbanBlock.header')}</Header>
      <Container>
        <Text>
          <Trans i18nKey="kanbanBlock.text" t={t} />
        </Text>
        <Kanban>
          <Trans i18nKey="kanbanBlock.kanban" t={t} />
        </Kanban>
      </Container>
    </StyledKanbanBlock>
  );
};

export default KanbanBlock;
