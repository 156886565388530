import React from 'react';

import { useTranslation, Trans } from 'lib/i18n';

import {
  StyledQuotesBlock,
  Title,
  SubTitle,
  Quotes,
  QuoteContainer,
  QuoteText,
  QuoteAuthor,
  QuoteImage,
  AuthorName,
  AuthorPost,
} from './styles';

import author1Img from './quote-adam-savage.png';
import author2Img from './quote-mark-andreesen.png';
import author3Img from './quote-duncan-jones.png';

interface Props {
  author: string;
  post: string;
  image: StaticImageData;
  children: React.ReactNode;
}

const Quote: React.FC<Props> = ({ author, post, image, children }) => (
  <QuoteContainer>
    <QuoteText>{children}</QuoteText>
    <QuoteAuthor>
      <QuoteImage src={image} alt={author} />
      <AuthorName>{author}</AuthorName>
      <AuthorPost>{post}</AuthorPost>
    </QuoteAuthor>
  </QuoteContainer>
);

const QuotesBlock = () => {
  const { t } = useTranslation('about');
  return (
    <StyledQuotesBlock>
      <Title>
        <Trans i18nKey="quotesBlock.title" t={t} />
      </Title>
      <Quotes>
        <Quote
          author={t('quotesBlock.quote1.author')}
          post={t('quotesBlock.quote1.post')}
          image={author1Img}
        >
          <Trans i18nKey="quotesBlock.quote1.text" t={t} />
        </Quote>
        <Quote
          author={t('quotesBlock.quote2.author')}
          post={t('quotesBlock.quote2.post')}
          image={author2Img}
        >
          <Trans i18nKey="quotesBlock.quote2.text" t={t} />
        </Quote>
        <Quote
          author={t('quotesBlock.quote3.author')}
          post={t('quotesBlock.quote3.post')}
          image={author3Img}
        >
          <Trans i18nKey="quotesBlock.quote3.text" t={t} />
        </Quote>
      </Quotes>
      <SubTitle>
        <Trans i18nKey="quotesBlock.subTitle" t={t} />
      </SubTitle>
    </StyledQuotesBlock>
  );
};

export default QuotesBlock;
